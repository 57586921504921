/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff1a;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999;
}


/* Typewriter effect */

.dynamic-text {
  position: relative;
}

.dynamic-text:after {
  content: "|";
  /* position: absolute; */
  /* left: calc(100%); */
  color: #999;
  font-weight: 200;
}

.dynamic-text.fade:after {
  animation: fade 1s infinite ease-in-out;
}

@keyframes fade {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wide-chart svg,
.stock-chart svg,
.e-series-chart svg,
.widget-body svg,
.eco-chart svg,
.option-content.Item-Body svg,
.home-top-chart svg,
.portfolio-char svg,
.hist-trend svg {
  width: 100%;
  height: auto;
  /* shape-rendering: geometricPrecision; */
  shape-rendering: auto;
}

.fullscreenElement:fullscreen .Item-Body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.life-story-chart:fullscreen .fullscreen-chip {
  font-size: 1rem !important;
  top: 4.3rem !important;
  right: -1rem !important;
  padding: 10px !important;
}

.fair-value-chart:fullscreen .fullscreen-chip {
  font-size: 1rem !important;
  top: 1.3rem !important;
  right: 1rem !important;
  padding: 10px !important;
}

.life-story-chart:fullscreen .full-icon,
.fair-value-chart:fullscreen .full-icon {
  font-size: 1.3rem !important;
}

.life-story-chart:fullscreen .toggle-paper {
  max-width: 40% !important;
}

.life-story-chart:fullscreen .life-toggle button {
  font-size: 1rem !important;
}

.life-story-chart:fullscreen .widget-body {
  margin-top: 2rem;
}

.life-story-chart:fullscreen .widget-chart {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  height: 100vh;
  /* width: auto; */
  margin-bottom: 10rem;
}

.life-story-chart:fullscreen .widget-chart svg text {
  font-size: 1.5rem !important;
}

.fair-value-chart:fullscreen .fairvalue-chart svg text,
.fair-value-chart:fullscreen .chart-legend .MuiTypography-p {
  font-size: 1rem !important;
}

.fair-value-chart:fullscreen .chart-legend .MuiSvgIcon-root {
  font-size: 1.5rem !important;
  margin-left: 1rem;
}

.life-story-chart:fullscreen .option-title,
.fair-value-chart:fullscreen .option-title {
  margin: 1rem;
  font-size: 1rem;
  font-weight: 600;
}

.fullscreenElement:fullscreen .Item-Body svg,
.fairvalue-chart svg {
  height: 100%;
  width: 100%;
}

/* #financials {
  position: relative;
  border-top: 52px solid transparent;
  margin-top: -50px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}

#financials:before {
  content: "";
  position: absolute;
  top: -25px;
  left: 0;
  right: 0;
  border-top: 1px solid transparent;
} */

/* .alerttarget:target  {
  border-color: #0969da !important;
  outline: none !important;
  box-shadow: 0 0 0 1px #0969da !important;
} */


/* -----------------------to be removed----------------------- */


.rightContent.pri-wrapper {
  /* margin-bottom: 50px; */
  min-height: 100vh;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  /* padding-top: 40px; */
  /* background: linear-gradient(to bottom, rgba(231, 235, 244) 29%, rgba(255, 255, 255) 150%); */
  /* background: #e7ebf4 !important; */
  background: #f2f2f2 !important;
}

/* .plan-image {
    position: relative
} */


.bg-gruf-blue {
  background-color: #174C86 !important;
  color: #e7ebf4 !important;
}

.zindex-11 {
  z-index: 11;
}

.pri-wrapper .container {
  max-width: 960px;
  position: relative;
  /* min-height: 100vh; */
  /* min-height: 85vh; */
}

.pri-head {
  text-align: center;
  /* margin-bottom: 40px; */
}

.pri-head h2 {
  font-size: 1rem;
  text-align: start;
}

.pri-item {
  position: relative;
  transition: all .3s ease;
  max-width: 340px;
  /* border-top: 4px solid #174C86; */
  /* border-radius: 15px; */
}

.pri-classification {
  /* padding-bottom: 16px; */
  color: #666;
  /* font-size: 14px; */
  font-size: .83rem;
  /* min-height: 100px; */
  min-height: 3.5rem;
  color: #174C86;
}

/* .pri-item:hover { */
/* margin-bottom: -30px;
    margin-top: -30px; */
/* transform: scale(1.1); */
/* border: 1px solid #174C86;
    border-top: 4px solid #174C86;
} */

.pri-recommend {
  position: absolute;
  z-index: 15;
  text-align: center;
  width: 100%;
  background-color: #174C86;
  color: #fff;
  top: -32px;
  padding: 4px 0;
}

.pri-header {
  text-align: center;
}

.pri-header .pri-title {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: .15rem;
}

.pri-header .billing-text,
.pri-header .features-text {
  font-size: .833rem;
}

.MuiFormControl-root:checked+.MuiFormControlLabel-label:after {
  background-color: #000000 !important;
}

.pri-header .rounded-circle {
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: #fff;
  background-color: #174C86;
  padding: 2% 3%;
}

.pri-header .rounded-circle.billing-logo,
.pri-header .rounded-circle.features-logo {
  background-color: #6c757d;
}

.pri-billing-content .MuiFormControlLabel-label.MuiTypography-body1 {
  width: 100%;
}

.pri-billing-content .MuiBox-root.text-muted {
  font-size: 12px;
  color: #6c757d;
}

.pri-billing-content .monthly-price,
.pri-billing-content .anually-price {
  font-weight: 600;
}

.pri-billing-content .text-muted.monthly-text,
.pri-billing-content .text-muted.annually-text {
  letter-spacing: 1px;
  white-space: normal;
  /* font-size: 14px; */
  font-size: .87rem;
  line-height: 20px;
  text-align: center;
}

.billed-monthly-text,
.billed-annually-text {
  font-size: .8rem !important;
  text-transform: capitalize;
  font-weight: 400;
  letter-spacing: 1px;
  color: black !important;
}

/* .plan-item-annual,
.plan-item-month {
    display: flex;
} */

.plan-item-annual .text-annually,
.plan-item-month .text-monthly {
  margin: 0 1rem;
}

.discount-chip {
  position: absolute !important;
  top: 0;
  left: 0;
}

.billed-monthly-price,
.billed-annually-price {
  font-size: 3rem;
  /* font-size: 36px; */
  color: #221e46 !important;
  font-weight: 500;
  border-bottom: 1px solid #ececf5;
  /* width: 50%; */
  margin: auto;
}

.billing-dollar {
  font-size: 1rem;
  vertical-align: start;
}

/* .NumberBeforeDot {

} */

.NumberAfterDot {
  font-size: 1.15rem !important;
  /* vertical-align: text-top; */
  vertical-align: super;
}

.gruf-pricing-options {
  display: grid !important;
  grid-gap: 1rem 2rem;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, 14.5rem);
  width: 100%;
  margin: 0 auto 1.5rem;
}

.pricing-toggle-button {
  width: 14.5rem;
  min-height: 7.5rem;
  max-width: 100%;
  position: relative;
  border-radius: 5px;
  border-radius: .5rem;
  padding: 1rem;
  /* border: .16em solid rgba(153, 153, 153, 0.418) !important; */
  border: 2px solid #174d862f !important;
  background-color: #fff !important;
}

.pricing-toggle-button .MuiToggleButton-root.Mui-selected {
  border: 2px solid #174c86 !important;
  /* background-color: #cbdaf1 !important; */
}

.pricing-toggle-button.selected,
.retry-toggle-button.selected {
  border: 2px solid #174c86 !important;
  /* background-color: #cbdaf1 !important; */
}

.retry-toggle-button {
  border: 2px solid #174d862f !important;
}

.pricing-gruf .pricing-pro,
.pricing-gruf .pricing-enterprise {
  margin-left: .5rem;
  height: 20px;
  margin-bottom: 11px;
}

.pri-header,
.pri-content {
  box-shadow: 0 0.5rem 1rem hsl(211deg 69% 31% / 18%);
  border-radius: 5px;
  padding: 12px 18px;
  background-color: #fff;
  /* background-color: #e7ebf4; */
  z-index: 11;
  border: 1px solid #174c862e;
}


.pri-header .current-plan {
  font-size: 1.4rem;
  color: green;
}

.pri-content {
  position: relative;
  /* width: calc(100% - 30px); */
  /* background-color: #fff; */
  /* box-shadow: 5px 0 15px 0 rgba(0, 0, 0, 0.1); */
  /* border-radius: 10px; */
  /* padding: 12px 18px; */
  z-index: 11;
  transition: all .3s ease;
  width: 100%;
  margin-top: 1rem;
  /* cursor: pointer; */
}

.pri-content>p {
  font-size: 13px;
  /* color: #555; */
  padding-left: 16px;
  margin-top: -6px;
  margin-bottom: 12px;
  min-height: 20px;
}

/* .pri-item:hover .pri-content {
    padding: 90px 60px;
} */

.bg-pri-content {
  position: absolute;
  width: calc(100% - 18px);
  height: calc(100% + 30px);
  right: 0;
  top: 30px;
  border-radius: 10px;
  text-align: center;
}

.bg-pri-content a {
  letter-spacing: 0.02em;
  margin: .5em 0;
  font-size: 18px;
  line-height: 1.3em;
  font-weight: 700;
  color: #fff;
  position: absolute;
  bottom: 12px;
  text-transform: uppercase;
  width: 95%;
  left: 0;
}

.bg-pri-content a:hover {
  text-decoration: none;
}

.pri-price-detail {
  margin-bottom: 4px;
  margin-top: 0;
  color: #174C86;
  /* font-size: 40px; */
  font-weight: 500;
}

.pri-price-detail p {
  font-size: 12px;
  font-weight: 600;
}

.pri-price-detail .text-muted {
  font-size: 12px;
}

/* .pri-price-detail span:nth-child(2) {
    margin-right: 6px;
} */

.pri-price-detail span:last-child {
  font-size: 12px;
}

/* .pri-price-detail .dollar {
    font-size: 20px;
    margin-right: 5px;
} */

.pri-price-detail .dollar.dollar-prime {
  margin-right: 0;
}

.pri-price-detail p.entrp-price {
  /* font-size: 32px; */
  font-size: 14px;
  padding: 1.83rem 0;
  font-weight: 600;
}

.pri-content .pri-price {
  background: rgba(231, 235, 244);
  border-radius: 10px;
  margin-top: 8px;
  min-height: 5.1rem;
  letter-spacing: .05rem;
}

.pri-title {
  color: #174C86;
  margin: 24px 0;
  font-size: 32px;
}

.pri-line {
  height: 2px;
  border-radius: 15px;
  /* margin-bottom: 16px; */
  margin-bottom: 12px;
  /* background-color: #e1e1e1; */
  background-color: #174C86;
  width: 15%;
  margin-left: auto;
  margin-right: auto;
}

.pri-body {
  padding-top: 30px;
  /* padding-bottom: 90px; */
  /* padding-left: 60px;
    padding-right: 60px; */
}

/* .pri-body > .row > .col-md-4:nth-child(2) .pri-item .pri-content {
    z-index: 12;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
} */

.pri-desc {
  font-size: 14px;
  /* font-size: 12px; */
  font-weight: 600;
  color: #666666;
  margin-bottom: 12px;
  margin-top: 12px;
}

.pri-features {
  font-size: 14px;
  color: #666;
  list-style: disc;
  padding-left: 16px;
}

.pri-features li {
  padding: .5em 0;
}

.pri-details {
  /* min-height: 200px; */
  min-height: 155px;
  /* margin-top: 12px; */
  margin-top: 1.5rem;
  text-align: center;
}

.f-compare-wrapper {
  padding: 20px 0;
}

.f-compare-wrapper .f-compare-body {
  background-color: #174C86;
}

.f-compare-tbl {
  width: 100%;
  position: absolute;
  bottom: 0rem;
}

.f-compare-tbl td {
  padding: 1em 0;
  font-size: 14px;
  text-align: center;
  /* color: #666; */
  color: #174C86;
  margin: 1rem;
}

.f-compare-tbl td.f-compare-plan span {
  /* color: #444;  */
  color: #174C86 !important;
}

.f-compare-tbl td p {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 2px;
}

.f-compare-tbl td svg {
  font-size: 20px;
  color: #0e9e19;
}

.f-compare-tbl tr td:first-child {
  text-align: left;
  width: 50%;
}

.f-compare-tbl thead tr:nth-child(1) td:nth-child(2),
.f-compare-tbl thead tr:nth-child(1) td:nth-child(4) {
  background-color: #174C86;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.f-compare-tbl tbody tr td:nth-child(1) {
  color: white;
}

.f-compare-tbl tbody tr td:nth-child(1) span {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.719);
}

.f-compare-tbl tbody tr:nth-child(11) td {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.f-compare-tbl tr td:nth-child(2),
.f-compare-tbl tr td:nth-child(4) {
  /* background-color: #7db4ed; */
  background-color: rgb(172 205 242);
  margin-left: 10px;
  margin-right: 10px;
  width: 24%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.f-compare-tbl tr:nth-child(1) td:nth-child(2) p,
.f-compare-tbl tr:nth-child(1) td:nth-child(4) p {
  font-size: 12px;
}

.f-compare-tbl tr:nth-child(1) td:nth-child(2) .btn,
.f-compare-tbl tr:nth-child(1) td:nth-child(4) .btn {
  background: rgba(231, 235, 244);
  color: #174C86;
  font-size: 11px;
}

.f-compare-tbl tr:nth-child(1) td:nth-child(2) .btn:hover,
.f-compare-tbl tr:nth-child(1) td:nth-child(4) .btn:hover {
  color: white;
  background-color: #174C86;
  font-size: 11px;
}

.f-compare-tbl tr td:nth-child(3) {
  padding: 5px;
}

/* .f-compare-tbl thead td {
    border-bottom: 1px solid #e1e1e1;
} */

.f-compare-tbl thead td h5 {
  /* color: #174C86;
    font-size: 22px; */
  color: white;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: .15rem;
}

.f-compare-tbl thead td.f-compare-plan span {
  font-size: 16px;
}

.f-compare-plan.plan1 h5 {
  color: #00C68B;
}

.f-compare-plan.plan2 h5 {
  color: #EE510E;
}

.f-compare-plan.plan3 h5 {
  color: #2DA1EE;
}

.f-compare-plan.plan4 h5 {
  color: #FBAE21;
}

.f-compare-limit {
  font-size: 12px;
  /* color: #444; */
  color: #174C86;
}

.pri-offer {
  position: absolute;
  top: 12px;
  right: 12px;
  text-align: center;
}

.pri-discount span {
  padding: 4px 6px;
  background-color: #00C68B;
  color: #fff;
  border-radius: 4px;
}

.pri-offer-detail {
  font-size: 14px;
  color: #666;
  margin-bottom: 6px;
}

.price-btn {
  /* padding: 12px 0; */
  padding-top: 18px;
  /* padding-bottom: 25px; */
  padding-bottom: 18px;
}

.price-btn .btn {
  /* transition: .2s all ease-in-out; */
  transition: box-shadow .3s ease-in-out;
  font-size: 16px;
  line-height: 1.4;
  padding: 0.675rem 0.75rem;
  border-radius: 4px;
  font-weight: 600;
  color: #ffffff;
  background: rgb(23 76 134);
}

.price-btn .btn:hover {
  /* background: rgb(219, 226, 241); */
  color: #fff;
  font-weight: 600;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.price-btn p {
  /* font-size: 11px; */
  font-size: 14px;
  letter-spacing: 2px;
  margin-bottom: 0;
  white-space: pre-wrap;
}

.price-btn a {
  text-decoration: none;
}

/* .price-btn .btn:hover {
    background-color: #174C86;
    color: #fff;
} */

/* .sel-plan-wrapper {
    background-color: #fff;
    box-shadow: 5px 0 15px 0 rgba(0, 0, 0, 0.1);
    padding: 24px;
} */

.sel-plan-wrapper>.row {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.sel-plan-wrapper>.row>.col-md-6 {
  display: flex;
  /* justify-content: space-around; */
}

.s-plan-head {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 32px;
}

.plan-name {
  color: #174C86;
}

.plan-name h3 {
  font-size: 32px;
}

.plan-desc {
  color: #666;
  font-size: 14px;
}

.plan-price h2 {
  font-size: 40px;
}

.plan-filters {
  display: flex;
}

.plan-top>.row {
  align-items: center;
}

.plan-top {
  padding-bottom: 12px;
}

.plan-mode {
  /* padding: 12px 0; */
  padding-right: 24px;
}

.plan-mode-head {
  font-weight: 600;
  padding-bottom: 8px;
}

.plan-mode .btn.btn-secondary {
  background-color: #fff;
  color: #174C86;
  border-color: #174C86;
}

.plan-mode .btn.btn-secondary.active {
  background-color: #174C86;
  color: #fff;
  border-color: #174C86;
}

.s-plan-body {
  padding-top: 12px;
}

.pri-wrapper .container.plan-container {
  max-width: 960px;
}

.plan-features {
  padding: 1em 0;
}

.plan-features-body ul {
  margin-left: 2.5em;
}

.plan-features-body li {
  padding: .35em 0;
  color: #666;
  list-style: disc;
}

.trial-btn,
.buy-btn {
  /* padding: 30px 0; */
  padding: 10px 0;

}

.buy-btn input[type=submit] {
  border-radius: 4px;
  cursor: pointer;
  padding: .375rem .75rem;
}

.trial-btn .btn.btn-primary,
.buy-btn .btn.btn-primary {
  font-size: 18px;
  line-height: 1.6;
  /* transition: .3s all ease-in-out; */
  transition: box-shadow .3s ease-in-out;
  min-width: 150px;
}

.buy-btn input[type=submit] {
  background-color: rgba(255, 255, 255, 0.425);
  border: 1px solid #174C86;
  color: #174C86;
}

.buy-btn input[type=submit]:hover {
  /* background-color: #174C86;#174c8626 */
  background-color: #174c8626 !important;
  color: #fff;
}

.plan-action-choice span {
  padding-left: 30px;
}

.trial-btn span {
  font-size: 13px;
  color: #666;
}

/* .plan-mode .btn-secondary {
    background-color: #a0a9b3;
    border-color: #a0a9b3;
} */

.plan-feature-item {
  padding: .5em 0;
}

.plan-feature-item p {
  margin-bottom: 0;
}

.p-feat-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.p-feat-item svg {
  margin-right: 8px;
}

.pri-student-note {
  min-height: 36px;
  padding-bottom: 12px;
}

.pri-student-note svg {
  color: #174C86;
  margin-right: 3px;
  font-size: 14px;
}

.f-compare-tbl,
.pri-student-note span.deal {
  color: #174C86;
  margin-right: 8px;
}

.f-compare-body {
  background-color: #fff;
  /* box-shadow: 5px 0 15px 0 rgba(0, 0, 0, 0.1); */
  box-shadow: 0 0.5rem 1rem hsl(211deg 69% 31% / 18%);
  padding: 12px;
  /* border-radius: 15px; */
}

.f-compare-tile {
  /* text-align: center; */
  padding-bottom: 30px;
}

.f-compare-tile h2 {
  font-size: 1.2rem !important;
  font-weight: 700 !important;
}

.student-doc-form {
  font-size: 14px;
}

.student-doc-form label {
  font-weight: 600;
}

.student-doc-form .custom-file {
  margin-bottom: 1rem;
}

.student-doc-form .datePicker .react-datepicker-wrapper {
  position: relative;
  z-index: 1;
}

.plan-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.trial-btn.trial-msg span {
  font-size: 16px;
}

.trial-msg.subscribed-msg {
  /* min-height: 150px; */
  display: flex;
  align-items: center;
}

.trial-msg.subscribed-msg span {
  /* font-size: 20px; */
  color: #174C86;
  font-weight: 600;
}

.e-no-data {
  min-height: 300px;
  display: flex;
}

.plan-subscribed {
  position: absolute;
  top: 40px;
  right: 12px;
  color: #174C86;
  font-size: 1.2em;
}

.plan-subscribed svg {
  font-size: 2em;
}

.std-doc-wrapper {
  position: absolute;
  top: 0;
  width: 97%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
  transform: scale(0);
  transition: all .3s ease-in-out;
}

.std-doc-wrapper.show {
  transform: scale(1);
}

.std-doc-box {
  max-width: 400px;
  margin: auto;
  background-color: #fff;
  padding: 24px;
  text-align: center;
  color: #174C86;
  transform: translateY(20vh);
  box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.1), 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1);
  position: relative;
}

.std-doc-wrapper .cancel-btn {
  position: absolute;
  top: -4px;
  right: -8px;
  padding: 7px 12px;
  color: #fff;
  background-color: #174C86;
  border-radius: 50%;
  cursor: pointer;
}

.std-doc-icon {
  font-size: 4em;
  padding: 12px;
}

.std-doc-u-browser {
  position: relative;
}

.std-doc-u-browser input {
  visibility: hidden;
}

.std-doc-u-browser label {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  width: 100%;
  border: 1px solid #174C86;
  color: #174C86;
  transition: all .3s ease-in-out;
}

.std-doc-u-browser label:hover {
  background-color: #174C86;
  color: #fff;
}

/* .std-doc-head h4 {
    color: #174C86;
} */

.switch-p-note {
  font-size: 13px;
  color: #666;
}

.go-back-link .btn {
  /* background-color: #e7ebf4; */
  background-color: #ffff;
  /* box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); */
  font-size: 12px;
  margin-top: 2rem;
  color: #174C86;
}

.rightContent.pri-wrapper .help-center {
  /* background: linear-gradient(to bottom, rgba(231, 235, 244) 29%, rgba(255, 255, 255) 150%); */
  background-color: #f8f9fa;
}

.help-center h4,
.help-center p {
  letter-spacing: 1px;
}

.qanda {
  margin: 3rem;
  padding-right: 3rem;
  padding-left: 3rem;
  font-family: 'Ubuntu', 'Arial', sans-serif !important;
}

/* .qanda h1,
.qanda h2 {
    font-size: 1rem;
    letter-spacing: .01rem;
} */

.qanda .MuiCard-root {
  background: #fff !important;
  border: 1px solid #ececf5 !important;
  border-radius: 8px !important;
  overflow: hidden;
}

.qanda .question {
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 11px;
  margin-top: 11px;
  font-weight: 500;
}

.qanda .answer {
  font-size: 16px;
  line-height: 1.5;
  color: #221e46;
}

.min-h-10rem {
  min-height: 10rem;
}

/* .message__container {
    position: relative;
}

.container__content {
    overflow-y: hidden;
}

.container__fading {
    bottom: 0;
    left: 0;
    position: absolute;
    height: 20rem;
    width: 100%;
    background: linear-gradient(rgba(255, 255, 255, 0.01), #fff);
} */

@media screen and (max-width: 1024px) {

  .pri-body>.row>.col-md-3:first-child,
  .pri-body>.row>.col-md-3:nth-child(2) {
    margin-bottom: 100px;
  }

  .pri-item:hover {
    transform: scale(1);
  }

  .pri-body {
    padding-left: 0;
    padding-right: 0;
  }

  .pri-content {
    width: 100%;
  }

  .pri-desc {
    font-size: 15px;
  }
}

@media screen and (max-width: 768px) {

  .pri-content h2,
  .pri-content h2 span {
    font-size: 30px;
  }

  .pri-title {
    margin-bottom: 24px;
  }

  .pri-head h1,
  .f-compare-tile h1 {
    font-size: 2.2em;
  }

  .f-compare-tbl thead td.f-compare-plan span {
    font-size: 14px;
  }

  .f-compare-tbl thead td h5 {
    /* font-size: 21px; */
    font-size: 1rem;
  }

  .f-compare-tbl td p {
    font-size: 13px;
  }

  .f-compare-tbl td {
    font-size: 13px;
  }

  .f-compare-limit {
    font-size: 11px;
  }

  .f-compare-tbl td svg {
    font-size: 18px;
  }

  .pri-body>.row>.col-md-4 {
    flex: 0 0 50%;
    max-width: 50%;
    padding-bottom: 30px;
  }

  .std-doc-wrapper {
    width: 96%;
  }
}

@media screen and (max-width: 480px) {
  .pri-body>.row>.col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pri-head {
    margin-bottom: 0;
  }

  .pri-head h2 {
    /* font-size: 1.7rem; */
    line-height: 1.4;
    top: 24px;
  }

  .f-compare-wrapper {
    display: none;
  }

  .rightContent.pri-wrapper {
    margin-bottom: 0;
  }

  .pri-body {
    padding-bottom: 0;
  }

  .pri-item {
    padding-bottom: 30px;
  }

  .col-md-4:first-child .pri-item {
    padding-bottom: 56px;
  }

  .plan-right {
    padding-top: 16px;
  }

  .std-doc-box {
    max-width: 360px;
    box-shadow: none;
  }

  .s-plan-body>.row {
    flex-direction: column-reverse;
  }

  .std-doc-wrapper {
    width: 93%;
  }

  .trial-btn,
  .buy-btn {
    padding: 8px 0;
  }

  .s-plan-head {
    padding-bottom: 20px;
  }

  .s-plan-body {
    padding-top: 0;
  }

  .pri-wrapper .container {
    padding: 0;
  }

  .go-back-link {
    margin-right: 20px !important;
  }

  .pri-body-features {
    display: none;
  }

  .pri-body>.row>.col-md-4 {
    padding-bottom: 0;
  }

  .qanda {
    margin: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .min-h-10rem {
    margin-bottom: 2rem;
    min-height: auto;
  }

  .plan-image .container .hero-text,
  .no-plan-image .container .hero-text {
    font-size: 34px;
  }

  .plan-item-annual,
  .plan-item-month {
    display: grid;
  }

  .plan-item-annual .text-annually,
  .plan-item-month .text-monthly {
    margin: 0 !important;
  }

}

@media screen and (max-width: 400px) {
  .plan-filters {
    flex-wrap: wrap;
  }

  .plan-filters .plan-mode:last-child {
    padding-top: 16px;
  }
}

@media screen and (max-width: 360px) {
  .std-doc-wrapper {
    width: 100%;
  }

  .std-doc-box {
    max-width: 300px;
  }
}

/*  waves css  */
/* .parallax use {
  animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.parallax use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}

.parallax use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
} */


section:target {
  /* border-color: #174c86; */
  /* border: 1px solid #174c86; */
  border-radius: 7px;
  outline: none !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px -2px,
    rgba(0, 0, 0, 0.14) 0px 7px 10px 1px,
    rgba(0, 0, 0, 0.12) 0px 2px 16px 1px;
  /* box-shadow: 0 0 #0000,
    0 0 #0000,
    0 4px 6px -1px rgba(0, 0, 0, .1),
    0 2px 4px -2px rgba(0, 0, 0, .1); */
  scroll-margin-top: 77px;
}

/* blog linear grad */
.postContent {
  max-width: none;
  color: rgba(0, 0, 0, 0);
  background-clip: text;
  background-image: linear-gradient(to bottom, var(--blog-gradient-stops));
  --blog-gradient-from: #111827;
  --blog-gradient-stops: var(--blog-gradient-from), var(--blog-gradient-to, rgba(17, 24, 39, 0));
  --blog-gradient-to: transparent;
}

@keyframes slide {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}



/* // THE LOADING EFFECT
.skeleton {
  display: block;
  background-color: rgba(0, 0, 0, 0.11);
  position: relative;
  -webkit-mask-image: -webkit-radial-gradient(center, white, black);
  height: 190px;
  overflow: hidden;
}

.skeleton:after {
  animation: 1.6s linear 0.5s infinite normal none running animation-wiooy9;
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.04), transparent);
  content: "";
  position: absolute;
  transform: translateX(-100%);
  inset: 0px;
} */


/* chart-builder css */

.chartPage.rightContent {
  padding: 0;
}

.chart-container .EmptyScreenWrapper {
  top: 40%;
  transform: translate(-50%, 0);
}

.rightContent.chart-right {
  width: calc(100% - 64px);
}

.chart-left .sidebar {
  display: initial;
  width: auto;
  height: calc(100vh - 58px);
}